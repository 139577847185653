import { createI18n } from 'vue-i18n'

// Import translation messages
import en from './locales/en.json'
import es from './locales/es.json'
import pt from './locales/pt.json'
import ko from './locales/ko.json'

const messages = {
  en,
  es,
  pt,
  ko,
}

// Set up language in localStorage if not already set. Default language depends on browser language.
if (!localStorage.getItem('language')) {
  localStorage.setItem('language', navigator.language.split('-')[0])
}

const i18n = createI18n({
  legacy: false, 
  locale: localStorage.getItem('language'), // Set default locale e.g. 'en'
  fallbackLocale: 'en', // Set fallback locale
  messages,
})

export default i18n
