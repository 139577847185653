export const USER_LOCAL_STORAGE_KEY = 'um-user'
export const DEFAULT_DIALOG_WIDTH = '95%'
export const PUBLIC_PAGES = [
  'Login',
  'ForgotPassword',
  'ResetPassword',
  'HelperSignUp',
  'DummyHelperSignUp',
  'BookingPage',
  'EventListPage',
  'HelpCenterStudentPage',
  'PrivacyPolicyPage',
  'StudentSignUp'
]
export const ADMIN_PAGES = ['SendHelperSignUpEmail']

// Event colors from https://developers.google.com/calendar/api/v3/reference/colors#resource
export const GC_COLOR_OPTIONS = {
  1: { background: '#a4bdfc', foreground: '#1d1d1d' },
  2: { background: '#7ae7bf', foreground: '#1d1d1d' },
  3: { background: '#dbadff', foreground: '#1d1d1d' },
  4: { background: '#ff887c', foreground: '#1d1d1d' },
  5: { background: '#fbd75b', foreground: '#1d1d1d' },
  6: { background: '#ffb878', foreground: '#1d1d1d' },
  7: { background: '#46d6db', foreground: '#1d1d1d' },
  8: { background: '#e1e1e1', foreground: '#1d1d1d' },
  9: { background: '#5484ed', foreground: '#1d1d1d' },
  10: { background: '#51b749', foreground: '#1d1d1d' },
  11: { background: '#dc2127', foreground: '#1d1d1d' },
}

export const ONLINE_GROUP_EVENT_ID = 1
export const ONLINE_HABIT_EVENT_ID = 2
export const ONLINE_GROUP_EVENT_NAME = 'Online Group'
export const ONLINE_HABIT_EVENT_NAME = 'Online Habit'

export const VARIABLE_OPTIONS = [
  {
    value: '[EVENT_NAME]',
    example: '1:1',
  },
  {
    value: '[ZOOM_LINK]',
    example: '(Zoom link)',
  },
  {
    value: '[EVENT_DATE_AND_TIME]',
    example: '6:00 PM, Wednesday, March 20, 2024 (EDT)',
  },
  {
    value: '[START_IN_MINUTE]',
    example: 'in 30 min',
  },
  {
    value: '[FIRST_NAME]',
    example: 'Alex',
  },
  {
    value: '[LAST_NAME]',
    example: 'Kim',
  },
  {
    value: '[CENTER_NAME]',
    example: 'Bayside',
  },
  {
    value: '[LOCATION]',
    example: '(Zoom link or center address)',
  },
]
