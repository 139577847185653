<template>
  <el-row justify="center" style="margin-top: 20px">
    <div class="login-container">
      <div class="logo-container">
        <img src="@/assets/logo.png" class="logo" />
      </div>

      <el-form
        ref="LoginRef"
        :model="form"
        :rules="rules"
        label-position="top"
        @submit.prevent
      >
        <el-form-item :label="$t('Email')" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>

        <el-form-item :label="$t('Password')" prop="password">
          <el-input v-model="form.password" type="password" show-password />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="signIn"
            :loading="loading"
            style="width: 100%"
          >
            {{ $t('Sign_In') }}
          </el-button>
        </el-form-item>
      </el-form>

      <el-button
        type="primary"
        link
        @click="$router.push({ name: 'ForgotPassword' })"
      >
        {{ $t('Forgot_password') }}
      </el-button>

      <!-- NOTE: 앱 심사를 위한 dummy sign up page -->
      <!-- <el-row justify="center">
        <el-button
          type="primary"
          link
          @click="$router.push({ name: 'DummyHelperSignUp' })"
          style="margin-top: 15px"
        >
          Don't have an account? Sign up
        </el-button>
      </el-row> -->
    </div>
  </el-row>
</template>

<script>
import AuthService from '@/services/AuthService'
import utils from '@/helper/utils'
import { USER_LOCAL_STORAGE_KEY } from '@/helper/constants'

export default {
  data() {
    return {
      form: {},
      loading: false,
      rules: {
        email: [{ required: true, message: this.$t('Please_enter_Email') }],
        password: [
          { required: true, message: this.$t('Please_enter_Password') },
        ],
      },
    }
  },
  methods: {
    async signIn() {
      try {
        await this.$refs.LoginRef.validate()
      } catch (e) {
        console.log(e)
        return
      }

      try {
        this.loading = true

        const resp = await AuthService.login({
          email: this.form.email.trim(),
          password: this.form.password,
        })
        localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(resp.data))
        this.$store.dispatch('auth/initProfile')

        // Send event to Flutter WebView
        utils.sendEventToFlutter('SIGN_IN')

        this.$router.push('/')
      } catch (e) {
        this.loading = false
        utils.handleHttpError(e, this)
      }
    },
  },
}
</script>

<style scoped>
.login-container {
  width: 400px;
  text-align: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  width: 30px;
}
</style>
